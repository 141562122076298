<template>
    <div class="allcase-page">
        <div class="user-sel">
            <el-input size="small" placeholder="请输入案件编号检索" v-model="selCon.username"
                style="width:280px;margin-right:10px;"></el-input>
            <el-date-picker
                v-model="selCon.outCaseTime"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="margin-right:10px;">
            </el-date-picker>
            <el-select v-model="selCon.name" placeholder="请选择案件状态" size="small" style="margin-right:10px;">
                <el-option key="待接单" label="待接单" value="待接单"></el-option>
                <el-option key="维修中" label="维修中" value="维修中"></el-option>
                <el-option key="已维修" label="已维修" value="已维修"></el-option>
                <el-option key="已核定" label="已核定" value="已核定"></el-option>
                <el-option key="已复核" label="已复核" value="已复核"></el-option>
            </el-select>
            <el-input size="small" placeholder="出险地点" v-model="selCon.username"
                style="width:280px;margin-right:10px;"></el-input>
            <el-button icon="el-icon-search" type="primary" size="small" @click="userSel">查询</el-button>
        </div>
        <div class="user-table">
            <el-table :data="tableData" size="medium" border style="width: 100%">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="reportCaseNum" label="报案号" width="160" align="center">
                </el-table-column>
                <el-table-column prop="reportTime" label="报案时间" width="110" align="center">
                </el-table-column>
                <el-table-column prop="outDangerTime" label="出险时间" width="110" align="center">
                </el-table-column>
                <el-table-column prop="outDangerAddr" label="出险地点" align="center">
                </el-table-column>
                <el-table-column prop="surveyor" label="查勘员" width="120" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" width="160" align="center">
                </el-table-column>
                <el-table-column prop="status" label="案件状态" width="100" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==0" style="color:#ff0000;">未派单</span>
                        <span v-else-if="scope.row.status==1" style="color:#fa7122;">已派单</span>
                    </template>
                </el-table-column>
                <el-table-column prop="dispatchTime" label="分配时间" show-overflow-tooltip header-align="center">
                </el-table-column>
                <el-table-column prop="repairPeo" label="维修人员" width="120" align="center">
                </el-table-column>
                <el-table-column prop="caseDesc" label="案件说明" show-overflow-tooltip header-align="center">
                </el-table-column>
                <el-table-column label="操作" header-align="center" min-width="260" width="280" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="handle(scope.row)">查看详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination small background layout="prev, pager, next" :total="1000"
                style="margin-top:15px;text-align: right;">
            </el-pagination>
        </div>
        <el-dialog title="案件分配" :visible.sync="dispatchDialog" custom-class="my-dialog-style" @close="clearDisInfo">
            <div class="dis-case-info">
                <el-descriptions class="margin-top" title="案件基本信息" :column="3" :size="'medium'">
                    <el-descriptions-item label="案件编号">{{disCaseObj.caseNum}}</el-descriptions-item>
                    <el-descriptions-item label="报案号">{{disCaseObj.reportCaseNum}}</el-descriptions-item>
                    <el-descriptions-item label="报案时间">{{disCaseObj.reportTime}}</el-descriptions-item>
                    <el-descriptions-item label="被保险人">{{disCaseObj.insurePeo}}</el-descriptions-item>
                    <el-descriptions-item label="出险时间">{{disCaseObj.outDangerTime}}</el-descriptions-item>
                    <el-descriptions-item label="出险单位">{{disCaseObj.outDangerUnit}}</el-descriptions-item>
                    <el-descriptions-item label="出险地点">{{disCaseObj.outDangerAddr}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <el-table ref="multipleTable" :data="peoList" size="medium" border style="width: 100%" @row-click="singleElection">
                <el-table-column align="center" width="55" label="选择">
                    <template slot-scope="scope">
                        <!-- 可以手动的修改label的值，从而控制选择哪一项 -->
                        <el-radio class="radio" v-model="selPeoId" :label="scope.row.id">&nbsp;</el-radio>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="160" align="center">
                </el-table-column>
                <el-table-column prop="userSex" label="性别" width="100" align="center">
                </el-table-column>
                <el-table-column prop="userTel" label="联系方式" width="120" align="center">
                </el-table-column>
                <el-table-column prop="userDesc" label="擅长领域" align="center">
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dispatchDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="dispatchCase" size="small">确定分配</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
export default {
    name: "NoDis",
    data() {
        return {
            tableData: [],
            peoList:[],//维修工list
            selCon: {
                username: "",
                outCaseTime:"",
                name: ""
            },
            dispatchDialog:false,
            disCaseObj:{},//待派单的案件对象
            selPeoId:"",//已选择的用户id
            selPeoObj:{},//已选择的用户对象
        }
    },
    created() {
        this.initPage();
    },
    methods: {
        /**页面数据加载 */
        initPage(){
            const that = this;
            that.request.get("case/pageAlready").then(res => {
                that.tableData = res.data;
            });
        },
        /**初始化维修工的列表信息。用户点击《派单》时触发加载。 */
        initRepairPeo(e){
            const that =this;
            that.request.get("sysuser/getRepairPeo").then(res=>{
                if(res.code === 200){
                    that.peoList = res.data;
                    that.dispatchDialog=true;
                    that.disCaseObj = e;
                }
            });
        },
        /**修改案件信息 */
        editCase(id){
            this.$router.push({path:"/setting/case/add",query: { id:id}})
        },
        /**删除案件 */
        delCase(id){
            const that = this;
            that.request.get("case/delete",{id:id}).then(res =>{
                if(res.code===200){
                    that.initPage();
                }
            });
        },
        /**维修工列表选择操作 */
        singleElection(row){
            this.selPeoId = row.id;
            this.selPeoObj = row;
        },
        /**派单业务逻辑：选择案件、选择人员之后即可派单 */
        dispatchCase(){
            const that = this;
            if(that.selPeoObj.id&&that.disCaseObj.id){
                var _params = {
                    caseId:that.disCaseObj.id,
                    userId:that.selPeoObj.id,
                    remark:that.disCaseObj.caseNum+"被分配给"+that.selPeoObj.name
                }
                that.request.post("case/dispatch",_params).then(res=>{
                    console.log(res);
                    if(res.code===200){
                        that.dispatchDialog = false;
                        that.initPage();
                    }
                });
            }else{
                that.$notify.error({
                    title: '错误',
                    message: '维修工必须要选择！！！'
                });
            }
        },
        /**分配完成、弹窗关闭清空相关信息 */
        clearDisInfo(){
            this.selPeoId="";
            this.selPeoObj={};
            this.disCaseObj={};
        },
        /**用户检索 */
        userSel() {
            
        },
        handle(){

        }
    }
}
</script>
<style lang="less" scoped>
.allcase-page{
    .user-sel {
        display: flex;
        margin-bottom: 15px;
    }

    .user-table {
        margin-top: 15px;
    }
}
</style>